import "./index.scss";
import { NewBadge } from "../../../../../assets";

export const NewRecordNote = () => {
  return (
    <div className="new-record-note-wrapper">
      <img alt="" src={NewBadge} />
    </div>
  );
};
