import "./index.scss";

export const GuildBackground = () => {
  return (
    <div className="about-guild-container">
      <div className="about-guild">
        <h1>Who Are We?</h1>
        <div>
          <p>
            <b>Completely Trash</b> is a hardcore PvE (Raids/Strikes) guild in Guild Wars 2
          </p>
          <p>
            [cT] was formed with the purpose of mastering/optimizing strikes and raids. We are relatively new compared to the veteran hardcore guilds but we have built our own
            thing.
          </p>
        </div>
      </div>
      <div className="about-guild">
        <h1>General Information</h1>
        <p>Our main goal is to have fun in the competitive realm of Guild Wars 2 because the casual world bore us.</p>
        <p>
          We aim to get the fastest speed clears we can possible get in <b>Low Men</b> and sometimes 10 men shenanigans. This creates time investment into theory crafting and
          specific raid/strike boss trainings.
        </p>
        <p>
          We participate in competitive events such as the <a href="https://gw2raidleague.com/">Raid League</a> or{" "}
          <a href="https://hardstuck.gg/gw2/raid-rankings">Hard Stuck Live Competitions</a> for fun and records kill in <a href="https://www.speedrun.com/gw2">Speed Clear.</a>
        </p>
      </div>
    </div>
  );
};
