// General
export const cT = "Completely Trash";
export const minWidthSupport: number = 375;
export const clickPropagation: string = "click-propagation";
export const paginationNumber: number = 8;

// Text Msgs
export const clipboardTitle = "Clipboard Bingo Image";

// Local
export const ctNews = "cT-news";

// Style
export const activeLinkColor = "#e08821";
