
import './index.scss';

export const BackgroundBorderLineEffect = () => {
    return (
        <>
            <div className='bg-line-effect' style={{right: 0}}></div>
            <div className='bg-line-effect' style={{left: 0}}></div>
        </>
    );
}